<template>
  <div id="employee-edit-general-data-tab">
    <!-- WEBCAM -->
    <webcam-photo ref="webcam" @snapShot="onValueChanged" />
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex items-start flex-col lg:flex-row">
            <div @mouseover="mouseOver = true" @mouseleave="mouseOver = false" @click="loadCamera" id="photo-loading" class="cursor-pointer p-2 vs-con-loading__container mt-6" style="position: absolute; width:200px; height:200px">
              <feather-icon v-if="mouseOver" svgClasses="h-16 w-16 stroke-current text-success" icon="CameraIcon" style="margin-left: 55px; margin-top: 54px" />
            </div>
            <img :src="(user.photo && user.photo.url) ? user.photo.url : user.photo || noUserPhoto" class="mt-6 mr-8 rounded h-56 w-56" @load="closeLoading" />
            <div class="w-full">
              <div class="vx-row">
                <div class="vx-col md:w-full w-full mt-0">
                  <vs-input class="w-full mt-0" label="Nome" v-model="user.name" data-vv-as="Nome" v-validate.initial="'required|alpha_spaces'" name="name" />
                  <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input class="w-full" label="CPF" v-model="user.cpf" v-mask="['###.###.###-##','##.###.###/####-##']" data-vv-as="CPF" v-validate="'cpf'" name="cpf" />
                  <span class="text-danger text-sm" v-show="errors.has('cpf')">{{ errors.first('cpf') }}</span>
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input class="w-full" label="RG/IE" v-model="user.rg" />
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input type="date" class="w-full" label="Data de Nascimento" v-model="user.date_of_birth" />
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <div>
                    <label class="text-sm">Sexo</label>
                    <div class="mt-2">
                      <vs-radio v-model="user.gender" vs-value="male" class="mr-4">Masc.</vs-radio>
                      <vs-radio v-model="user.gender" vs-value="feminine" class="mr-4">Fem.</vs-radio>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex items-end mt-2">
                <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" />
                <span class="leading-none font-medium">Endereço</span>
              </div>
              <hr>

              <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input class="w-full" label="CEP" placeholder="CEP" v-model="user.address.zip_code" v-mask="['#####-###']" @keyup="searchZipCode" />
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <label class="vs-input--label">Logradouro</label>
                  <v-select v-model="user.address.logradouro" :reduce="option => option.value" clearable :options="logradouroOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                    <template #no-options="{}">
                      Desculpe, nenhum resultado encontrado.
                    </template>
                  </v-select>
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input class="w-full" label="Endereço" v-model="user.address.street" />
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input class="w-full" label="Número" v-model="user.address.number" type="number" placeholder="S/N" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="Complemento" v-model="user.address.complement" />
        </div>
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="Bairro" v-model="user.address.neighborhood" />
        </div>
        <div class="vx-col md:w-1/4_ w-full mt-2">
          <label class="vs-input--label">Cidade</label>
          <v-select v-model="user.address.city_id" :reduce="option => option.value" :placeholder="cityOptions.length ? 'Selecione' : 'Selecione um estado'" :options="cityOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">Estado</label>
          <v-select v-model="user.address.state_id" :reduce="option => option.value" @input="fillCity(user.address.state_id), user.address.city_id = null" :options="states" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">País</label>
          <v-select v-model="user.address.country" :reduce="option => option.value" :clearable="false" :options="countryOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="PhoneIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Contato</span>
      </div>
      <hr>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full" v-for="(phone,i) in user.phones" :key="i">
          <vs-input class="w-full mt-2" v-mask="['(##)#####-####','(##)####-####']" :label="phone.type === 'phone' ? 'Telefone' : phone.type === 'mobile' ? 'Celular' : phone.type === 'other' ? 'Outro' : ''" v-model="phone.phone" />
        </div>
        <div class="vx-col md:w-2/5 w-full">
          <vs-input class="w-full mt-2" label="E-mail" data-vv-as="E-mail" v-model="user.email" type="email" v-validate.initial="{ email: true, required: user.sistemAccess }" name="email" />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="InfoIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Acesso ao Sistema (Login)</span>
      </div>
      <hr>

      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mt-2">
          <vs-input class="w-full mt-0" label="Senha de Acesso" data-vv-as="Senha" v-model="user.password" type="password" v-validate.initial="'min:6'" name="password" />
          <span class="text-danger text-sm" v-show="errors.has('password')">{{ errors.first('password') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-2">
          <label class="vs-input--label">Senha</label>
          <small class="flex cursor-pointer">
            <feather-icon icon="InfoIcon" svgClasses="h-4 w-4 text-warning" class="mr-2" />
            <span>Deixe a senha em branco para não alterar ou insira uma nova senha caso deseje alterar.</span>
          </small>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save" :disabled="!validateForm">Alterar</vs-button>
          </div>
        </div>
      </div>
    </vx-card>
  </div>

</template>

<script>
import vSelect from 'vue-select'
import WebcamPhoto from '@/components/webcam-photo/WebcamNewPhoto'

import moduleStates from '@/store/state/moduleStates.js'

export default {
  components: {
    vSelect,
    WebcamPhoto
  },
  computed: {
    states () {
      return this.$store.getters['states/getStates']
    },
    validateForm () {
      return !this.errors.any()
    }
  },
  data () {
    return {
      user: {
        photo: null,
        name: '',
        cpf: '',
        rg: '',
        date_of_birth: '',
        gender: 'undefined',
        password: '',
        address: {}
      },
      localShow: false,
      mouseOver: null,
      mouseLeave: null,
      noUserPhoto: require('@/assets/images/user/material-design-user-icon-29.jpg'),

      statusOptions: [
        { label: 'Ativo',  value: 1 },
        { label: 'Inativo',  value: 0 }
      ],

      categoryCNHOptions: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'AB', value: 'AB' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' },
        { label: 'AC', value: 'AC' },
        { label: 'AD', value: 'AD' },
        { label: 'AE', value: 'AE' },
        { label: 'ACC', value: 'ACC' }
      ],

      countryOptions: [{ label: 'Brasil', value: 1 }],

      sistemAccessOptions: [
        { label: 'Não', value: false },
        { label: 'Sim',  value: true }
      ],

      logradouroOptions: [
        { label: 'Rua', value: 'Rua' },
        { label: 'Avenida', value: 'Avenida' },
        { label: 'Rodovia', value: 'Rodovia' },
        { label: 'Estrada', value: 'Estrada' },
        { label: 'Lote', value: 'Lote' },
        { label: 'Servidão', value: 'Servidão' },
        { label: 'Quadra', value: 'Quadra' },
        { label: 'Travessa', value: 'Travessa' },
        { label: 'Linha', value: 'Linha' }
      ],

      cityOptions:[]

    }
  },

  methods: {
    onValueChanged (value) {
      this.user.photo = value.base_img
    },
    loadCamera () {
      this.$refs.webcam.loadCamera()
    },
    openLoading () {
      this.$vs.loading({
        container: '#photo-loading',
        scale: 0.6
      })
    },
    closeLoading () {
      this.$vs.loading.close('#photo-loading > .con-vs-loading')
    },
    async save () {
      try {
        this.$vs.loading()
        const response = await this.$http.put(`${process.env.VUE_APP_API_URL}/user/${this.user.id}`, this.user)

        this.$store.commit('UPDATE_USER_INFO', JSON.parse(JSON.stringify(response.data)))

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    async searchZipCode ($event) {
      const zip_code = this.user.address.zip_code.trim().replace(/[^0-9]/g, '')

      if (zip_code.length === 8 && ($event.keyCode < 37 || $event.keyCode > 40)) { // $event.keyCode evita chamadas de API ao teclar setas

        this.$http._noHeaders = true //enviando um get sem Autorization no header
        const resp = await this.$http.get(`https://viacep.com.br/ws/${zip_code}/json`)
        if (resp.data.erro) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Este CEP não existe ou não foi encontrado.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }

        this.user.address.neighborhood = resp.data.bairro
        this.user.address.complement = resp.data.complemento

        //abaixo testo a primeira palavra da rua (logradouro), se existir removo da rua e seleciono o logradouro
        const rua = /Rua /i
        const avenida = /Avenida /i
        const rodovia = /Rodovia /i
        const estrada = /Estrada /i
        const lote = /Lote /i
        const servidao = /Servidão /i
        const quadra = /Quadra /i
        const travessa = /Travessa /i
        const linha = /Linha /i

        if (rua.test(resp.data.logradouro)) {
          this.user.address.logradouro = 'Rua'
          const regEx = new RegExp('Rua ', 'ig')
          this.user.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (avenida.test(resp.data.logradouro)) {
          this.user.address.logradouro = 'Avenida'
          const regEx = new RegExp('Avenida ', 'ig')
          this.user.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (rodovia.test(resp.data.logradouro)) {
          this.user.address.logradouro = 'Rodovia'
          const regEx = new RegExp('Rodovia ', 'ig')
          this.user.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (estrada.test(resp.data.logradouro)) {
          this.user.address.logradouro = 'Estrada'
          const regEx = new RegExp('Estrada ', 'ig')
          this.user.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (lote.test(resp.data.logradouro)) {
          this.user.address.logradouro = 'Lote'
          const regEx = new RegExp('Lote ', 'ig')
          this.user.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (servidao.test(resp.data.logradouro)) {
          this.user.address.logradouro = 'Servidão'
          const regEx = new RegExp('Servidão ', 'ig')
          this.user.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (quadra.test(resp.data.logradouro)) {
          this.user.address.logradouro = 'Quadra'
          const regEx = new RegExp('Quadra ', 'ig')
          this.user.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (travessa.test(resp.data.logradouro)) {
          this.user.address.logradouro = 'Travessa'
          const regEx = new RegExp('Travessa ', 'ig')
          this.user.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (linha.test(resp.data.logradouro)) {
          this.user.address.logradouro = 'Linha'
          const regEx = new RegExp('Linha ', 'ig')
          this.user.address.street = resp.data.logradouro.replace(regEx, '')
        } else {
          this.user.address.street = resp.data.logradouro
        }

        const state = this.states.find(o => o.initials === resp.data.uf)
        this.user.address.state_id = state.value
        await this.fillCity(state.value, resp.data.localidade)
      }
    },

    async fillCity (stateId, city = null) {
      this.cityOptions = []
      try {
        if (stateId) {
          const resp = await this.$http.get(`${process.env.VUE_APP_API_URL}/cities/${stateId}`)
          // this.cityOptions.push({ value: null, label: 'Selecione' })

          for (const k in resp.data) {
            this.cityOptions.push({ value: resp.data[k].id, label: resp.data[k].city })
          }
          if (city) {
            //procura pelo id, se não encontrar peocura pelo nome (motivo: da api recebo ID e do CEP recebo nome)
            let ct = this.cityOptions.find(x => x.value === city)
            if (!ct) {
              ct = this.cityOptions.find(x => x.label === city)
            }
            this.user.address.city_id = ct.value
          }
        }
      } catch (error) { console.error(error) }
    }
  },

  mounted () {
    if (this.user.address.state_id) {
      if (this.user.address.city_id) {
        this.fillCity(this.user.address.state_id, this.user.address.city_id)
      } else {
        this.fillCity(this.user.address.state_id)
      }
    }
  },

  created () {
    this.user = JSON.parse(JSON.stringify(this.$store.state.AppActiveUser))

    if (!this.user.addresses.length) this.user.address = { country: 1 }
    else this.user.address = this.user.addresses[0]
    this.user.address.country = 1

    const sortPhonesBy = ['mobile', 'phone', 'other']
    //AJUSTANDO OS TELEFONES VAZIOS
    if (!this.user.phones) this.user.phones = [
      {type: 'mobile', phone: null},
      {type: 'phone', phone: null},
      {type: 'other', phone: null}
    ]
    if (!this.user.phones.filter(phone => phone.type === 'mobile').length) {
      this.user.phones.unshift({id: null, phone: null, type: 'mobile' })
    }
    if (!this.user.phones.filter(phone => phone.type === 'phone').length) {
      this.user.phones.push({id: null, phone: null, type: 'phone' })
    }
    if (!this.user.phones.filter(phone => phone.type === 'other').length) {
      this.user.phones.push({id: null, phone: null, type: 'other' })
    }
    //ORDENANDO OS TELEFONES NA SEQUÊNCIA ABAIXO
    this.user.phones.sort((a, b) => sortPhonesBy.indexOf(a.type) - sortPhonesBy.indexOf(b.type))

    if (!moduleStates.isRegistered) {
      this.$store.registerModule('states', moduleStates)
      moduleStates.isRegistered = true
    }
    this.$store.dispatch('states/fetchStates')
  }
}
</script>

<style>
  .vue_select_drop_size_200 .vs__dropdown-menu {
    max-height: 200px;
  }
</style>
